import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './templates.module.css'
import { templates } from '../data/templates'
import Layout from '../layout'
import Template from '../components/Template'

const Templates = () => {
  return (
    <Layout>
      <div className={styles.templatePageContainer}>
        <header className={styles.header}>
          <h1 className={styles.title}>Templates</h1>
          <p className={styles.subTitle}>
            Use these templates as a starting point and customize them to your
            own fit.
          </p>
        </header>
        <div className={styles.templateContainer}>
          {templates.map(template => (
            <Template template={template} key={template.title} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Templates

Templates.propTypes = {
  templates: PropTypes.node,
}
